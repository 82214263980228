// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choices__inner {
  min-height: unset !important;
  padding: 3px;
  border-radius: 0;
  display: block;
  width: unset;
}
.choices__list--multiple .choices__item {
  background-color: #2878f0;
  border: 0;
  margin-bottom: 5px;
  float: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/bel-au-tag-input/component.less"],"names":[],"mappings":"AAAA;EACC,4BAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;AACD;AACA;EACC,yBAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;AACD","sourcesContent":[".choices__inner {\n\tmin-height: unset !important;\n\tpadding: 3px;\n\tborder-radius: 0;\n\tdisplay: block;\n\twidth: unset;\n}\n.choices__list--multiple .choices__item {\n\tbackground-color: rgb(40, 120, 240);\n\tborder: 0;\n\tmargin-bottom: 5px;\n\tfloat: left\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
