import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import Choices, { EventChoice, InputChoice } from 'choices.js';
import "choices.js/public/assets/styles/choices.css";

@autoinject
export class BelAuTagInputCustomElement {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValues!: any[];
	@bindable({ defaultBindingMode: bindingMode.toView }) choices!: any[];
	@bindable({ defaultBindingMode: bindingMode.oneTime }) labelKey = "name";
	@bindable({ defaultBindingMode: bindingMode.oneTime }) valueKey = "id";
	@bindable({ defaultBindingMode: bindingMode.oneTime }) disabled = false;

	private tagInput?: Choices; 
	constructor(private element: Element) {}

	@computedFrom("selectedValues.length")
	get selectedValueObserver() {
		if(this.selectedValues.length == 0) {
				this.tagInput?.removeActiveItems();
		}
		return "";
	}

	@computedFrom("choices.length")
	get choiceList() {
		if(!this.choices) return [];
		return this.choices.map(c => { return {label: c[this.labelKey], value: c[this.valueKey]}})
	}

	@computedFrom("choices.length")
	get choiceListObesrver() {
		setTimeout(()=>{
			this.tagInput?.refresh();
		},200)
		return "";
	}

	attached() {
		let element = this.element.querySelector(".tag-input");
		if(element) {
			this.tagInput = new Choices(element, {
				removeItemButton: true,
				choices: this.choiceList,
				shouldSort: false
			});

			const preselectedValues = this.choiceList.filter(c => this.selectedValues.find(sv => sv[this.valueKey] === c.value)).map((c: InputChoice) => c.value);
			this.tagInput.setChoiceByValue(preselectedValues);
			if(this.disabled) {
				this.tagInput.disable();
				return;
			}
			element.addEventListener(
				'addItem', ()=>{this.updateSelectedValues()}, false,
			);
			element.addEventListener(
				'removeItem', ()=>{this.updateSelectedValues()}, false,
			);
		}
	}

	updateSelectedValues() {
		let selectedValues = this.tagInput?.getValue() as EventChoice[];
		this.selectedValues = this.choices.filter(c => selectedValues.find(sv => sv.value == c[this.valueKey]))
	}

}
