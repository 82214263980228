import { deepComputedFrom } from 'aurelia-deep-computed';
import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { UIPricingGroupRow } from 'pos/pricing-group-edit';
import { Area, IdName, MyHttpApi, PortionSize, PricingGroupRowUpdateRequest, SalesChannel, SalesRestriction } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class PricingGroupRowsCustomElement {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) rows: UIPricingGroupRow[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) client?: IdName;
	@bindable({ defaultBindingMode: bindingMode.toView }) editMode: boolean = false;
	@bindable({ defaultBindingMode: bindingMode.toView }) clientSpecific: boolean = true;
	@bindable({ defaultBindingMode: bindingMode.toView }) specify: boolean = false;
	@bindable({ defaultBindingMode: bindingMode.toView }) portionSizeList: PortionSize[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) salesChannelList: SalesChannel[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) salesRestrictionList: SalesRestriction[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) locationList: Location[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) areaList: Area[] = [];

	constructor(private api: MyHttpApi, private readonly notify: Notify, private element: Element) { }

	addRow() {
		let row = this.createNewRow();
		this.rows.push(row);
	}

	deleteRow(row: UIPricingGroupRow) {
		let newRows = this.rows.filter(r => r !== row);
		if (!newRows.length) {
			newRows.push(this.createNewRow());
		}
		this.rows = newRows;
	}

	@deepComputedFrom('rows')
	get validatePricingGroupRowObserver() {
		setTimeout(() => {
			const rows = Array.from(this.element.querySelectorAll(".results tr[data-rowkey]"));

			// reset validation
			rows.forEach(tr => tr.classList.remove("duplicated"));

			// do validation
			let isInvalid = false;
			rows.forEach(tr => {
				let duplicateRow = rows.find(r => tr.getAttribute("data-rowkey") === r.getAttribute("data-rowkey") && tr != r);
				if (duplicateRow) {
					isInvalid = true;
					duplicateRow.classList.add("duplicated");
					tr.classList.add("duplicated");
				}
			});
			if (isInvalid) {
				this.notify.info("common.duplicatedPrices", {});
			}
		}, 100)
		return "";
	}

	createNewRow() {
		let rowRequest: PricingGroupRowUpdateRequest = {
			portionSizeId: "",
			clientId: this.client?.id,
			isHidden: false,
		};
		return new UIPricingGroupRow(rowRequest, this.areaList);
	}
}
