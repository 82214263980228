import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { PortionSize, ProductPricingGroup, ProductResponse, SalesChannel, SalesRestriction, StorageUnit } from 'utils/api';

export interface UIProductPricingGroup extends ProductPricingGroup {
	portionSizeName?: string;
	salesChannelName: string;
	salesRestrictionName: string;
}

@autoinject
export class UiProductLayoutPricingGroupCustomElement {
	@bindable({ defaultBindingMode: bindingMode.toView }) portionSizeList!: PortionSize[];
	@bindable({ defaultBindingMode: bindingMode.toView }) salesChannelList!: SalesChannel[];
	@bindable({ defaultBindingMode: bindingMode.toView }) storageUnitList!: StorageUnit[];
	@bindable({ defaultBindingMode: bindingMode.toView }) productResponse?: ProductResponse;
	@bindable({ defaultBindingMode: bindingMode.toView }) productPricingGroupId?: string;
	@bindable({ defaultBindingMode: bindingMode.toView }) required!: boolean;
	@bindable({ defaultBindingMode: bindingMode.toView }) salesRestrictionList!: SalesRestriction[];
	@bindable({ defaultBindingMode: bindingMode.toView }) select!: (_choice?: UIProductPricingGroup) => void;

	private showModal = false;
	buttonElement?: HTMLButtonElement = undefined;

	constructor(private element: Element, private i18n: I18N) {

	}

	attached() {
		let el = this.element.getElementsByClassName("changePrice")[0];
		if (el) {
			this.buttonElement = <HTMLButtonElement>el;
		}
	}

	@computedFrom("productResponse.productPricingGroupList")
	get productPricingGroupList() {
		return this.productResponse?.productPricingGroupList || [];
	}

	@computedFrom("productPricingGroupList")
	get uiProductPricingGroupList() {
		const list = this.productPricingGroupList
			.filter(x => !x.isHidden && !x.deleteTime)
			.map(x => {
				const ps = this.portionSizeList.find(ps => ps.id === x.portionSizeId);
				const su = this.storageUnitList.find(x => x.id === this.productResponse?.product.storageUnitId);
				let item: UIProductPricingGroup = {
					...x,
					portionSizeName: (ps?.name + " / " + (ps?.isStorageSize ? su?.value : ps?.amount)),
					salesChannelName: this.salesChannelList.find(sc => sc.id === x.salesChannelId)?.name || this.i18n.tr("common.common", {}),
					salesRestrictionName: this.salesRestrictionList.find(sr => sr.id === x.salesRestrictionId)?.name || this.i18n.tr("common.noSalesRestriction", {}),
				};
				return item;
			});
		return list;
	}

	@computedFrom("productResponse.productPricingGroupList")
	get canShow() {
		return this.productResponse?.productPricingGroupList.filter(x => !x.isHidden).length;
	}

	choose(ppc?: UIProductPricingGroup) {
		this.select(ppc);
		this.showModal = false;
	}

	cancel() {
		this.showModal = false;
	}

	startShow() {
		this.showModal = true;
	}

	@computedFrom("productResponse.productPricingGroupList", "productPricingGroupId")
	get productPricingGroup() {
		return this.productResponse?.productPricingGroupList.find(x => x.id === this.productPricingGroupId);
	}

	@computedFrom("portionSizeList", "productPricingGroupId")
	get portionSize() {
		return this.portionSizeList.find(x => x.id === this.productPricingGroup?.portionSizeId);
	}

	@computedFrom("storageUnitList")
	get storageUnit() {
		return this.storageUnitList.find(x => x.id === this.productResponse?.product.storageUnitId);
	}

	@computedFrom("portionSize")
	get portionAmount() {
		return this.portionSize?.isStorageSize ? this.storageUnit?.value : this.portionSize?.amount;
	}

	@computedFrom("salesChannelList", "productPricingGroupId")
	get salesChannel() {
		return this.salesChannelList.find(x => x.id === this.productPricingGroup?.salesChannelId);
	}

	@computedFrom("salesRestrictionList", "productPricingGroupId")
	get salesRestriction() {
		return this.salesRestrictionList.find(x => x.id === this.productPricingGroup?.salesRestrictionId);
	}

	@computedFrom("productPricingGroup", "required")
	get valueObserver() {
		let warning = "";
		if (this.required && !this.productPricingGroup) {
			warning = this.i18n.tr("validation.choosePricingGroup", {});
		}
		if (this.buttonElement) {
			this.buttonElement.setCustomValidity(warning);
			this.buttonElement.reportValidity();
		}
		return "";
	}
}
