import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { UISalesRestrictionTime } from 'pos/sales-restriction-edit';
import { MyHttpApi } from 'utils/api';
import { weekdayList } from 'utils/repository-util';

@autoinject
export class SalesRestrictionTimesCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) rows: UISalesRestrictionTime[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled: boolean = true;

	private daysOfWeek = weekdayList;
  private translationsDayOfWeek: { [key: string]: string; }[] = [];

  constructor(private api: MyHttpApi, private i18n: I18N) { }

  async attached() {
    for (let key of this.daysOfWeek) {
      this.translationsDayOfWeek.push({
        id: key,
        name: this.i18n.tr(`DayOfWeek.${key}`),
      });
    }
  }

  sortList() {
		this.rows = (this.rows || []).sort((a, b) => {
      let ai = a.dayOfWeek ? this.daysOfWeek.indexOf(a.dayOfWeek) : -1
      let bi = b.dayOfWeek ? this.daysOfWeek.indexOf(b.dayOfWeek) : -1
			return ai - bi;
		});
	}

  addRestriction() {
    this.rows.push({});
  }

  deleteRestriction(x: UISalesRestrictionTime) {
    let rows = this.rows.filter(srt => srt !== x);
    if (!rows.length) {
      rows.push({});
    }
    this.rows = rows;
  }
}
